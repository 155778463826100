import { OtysErrorResponse, OtysSuccessResponse } from './json-rpc'

export enum FileField {
  CV_DOC = 'cvDoc',
  OTHER_DOCS = 'otherDocs',
}

export enum FileType {
  ACQUISITIE = 'Acquisitie',
  AFSPRAAK = 'Afspraak',
  BIJLAGE = 'Bijlage',
  CAMPUS_RECRUITMENT = 'Campus Recruitment',
  CONTACT = 'Contact',
  CONTACT_ACCOUNTMANAGEMENT = 'Contact Accountmanagement',
  CV = 'CV',
  EMOLUMENTEN = 'Emolumenten',
  GESPREKSVERSLAG = 'Gespreksverslag',
  HR_BELLIJN = 'HR Bellijn',
  KANDIDATEN_VOORGESTELD = 'Kandidaten voorgesteld',
  MAILWISSELING = 'Mailwisseling',
  NOTITIE = 'Notitie',
  OFFERTE = 'Offerte',
  OPDRACHTFORMULERING = 'Opdrachtformulering / (eind)evualatie',
  PKD_CONSULTANCY = 'PKD Consultancy',
  PROJECT_PERFORMANCE_MANAGEMENT = 'Project Performance Management',
  PROJECT_REORGANISEREN_TRANSFORMEREN = 'Project Reorganiseren & Transformeren',
  TARIEF_FACTURATIE_AFSPRAKEN = 'Tarief / facturatie afspraken',
  VERLENGING = 'Verlenging',
  VOORSTELSTUKJE = 'Voorstelstukje',
}

export const fileTypeIds = {
  '825': FileType.ACQUISITIE,
  '217': FileType.AFSPRAAK,
  '215': FileType.BIJLAGE,
  '1035': FileType.CAMPUS_RECRUITMENT,
  '216': FileType.CONTACT,
  '1034': FileType.CONTACT_ACCOUNTMANAGEMENT,
  '1': FileType.CV,
  '620': FileType.EMOLUMENTEN,
  '203': FileType.GESPREKSVERSLAG,
  '624': FileType.HR_BELLIJN,
  '1031': FileType.KANDIDATEN_VOORGESTELD,
  '1029': FileType.MAILWISSELING,
  '1038': FileType.NOTITIE,
  '1033': FileType.OFFERTE,
  '1037': FileType.OPDRACHTFORMULERING,
  '1036': FileType.PKD_CONSULTANCY,
  '1028': FileType.PROJECT_PERFORMANCE_MANAGEMENT,
  '1027': FileType.PROJECT_REORGANISEREN_TRANSFORMEREN,
  '1032': FileType.TARIEF_FACTURATIE_AFSPRAKEN,
  '1030': FileType.VERLENGING,
  '622': FileType.VOORSTELSTUKJE,
}

export type FileTypeKey = keyof typeof fileTypeIds

export type FileTypes = Record<string | number, FileType>

export enum FileDataClass {
  TK = 'TK',
  ATTACHED_DOCS = 'AttachedDocs',
}

export interface BufferFileProps {
  type: FileType | undefined
  name: string | undefined
  fileTypeId?: string | null
  data: Buffer | null
}

export interface BufferFilesProps {
  cvDoc: BufferFileProps
  otherDocs: BufferFileProps
  picture: BufferFileProps
}

export interface BlobFileProps {
  type: FileType | string
  name: string
  data: File | null
}

export interface BlobFilesProps {
  cvDoc: BlobFileProps
  otherDocs: BlobFileProps
}

export interface FileTypeAsOption {
  label: FileType
  value: FileType
}

export interface BindFileData {
  subject: string
  typeId: FileTypeKey
  private?: boolean
  alwaysOnTop?: boolean
  candidateUid: string
  fileUid: string
}

export interface FileData {
  id: string
  klantid: number
  id_entity: number
  class: FileDataClass | string
  id_rec: number
  dt: string
  date: string
  subject: string
  from: string
  pvar1: null | string
  pvar2: null | string
  pvar3: null | string
  pvar4: null | string
  pvar5: FileType | null | string
  pvar6: null | string
  pvar7: null | string
  pvar8: null | string
  pvar9: null | string
  pint1: null | boolean | number
  pint2: null | boolean | number
  pint3: null | boolean | number
  pint4: null | boolean | number
  pint5: null | boolean | number
  pint6: null | boolean | number
  pint7: null | boolean | number
  incoming_outgoing: string
  entityLinks: null | string
  alwaysOnTop: number
  stiplyStatus: null | string
  commercialMark: boolean
  commercialMarkEnabled: boolean
  processStep: string
  category: null | string
  downloadUrl?: string
}

export type FileWithDownloadUrl = FileData & { downloadUrl: string }

export interface ProfileFiles {
  cvDoc: FileWithDownloadUrl | null
  otherDocs: FileWithDownloadUrl[]
}

export type FileDataListResponse = OtysSuccessResponse<FileData[]> &
  OtysErrorResponse

export type UploadFileSuccessResData = Record<
  string,
  {
    ouid: string
    name: string
    mimeType: string
    size: number
  }
>

export type BindDocResponse = OtysSuccessResponse<string> & OtysErrorResponse

import { BlobFilesProps, BufferFilesProps, FileType } from '@/interfaces/files'
import { createInitValuesObject } from '../object'

export const blobfilesInitValues: BlobFilesProps = createInitValuesObject({
  cvDoc: {
    type: FileType.CV,
    name: '',
    data: null,
  },
  otherDocs: {
    type: '',
    name: '',
    data: null,
  },
})

export const bufferfilesInitValues: BufferFilesProps = createInitValuesObject({
  cvDoc: {
    type: undefined,
    name: undefined,
    fileTypeId: null,
    data: null,
  },
  otherDocs: {
    type: undefined,
    name: undefined,
    fileTypeId: null,
    data: null,
  },
  picture: {
    type: undefined,
    name: undefined,
    fileTypeId: null,
    data: null,
  },
})
